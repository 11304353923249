
import NotificationResource from 'common/services/resources/notification.js'
import ArrayService from 'common/services/array.js'
import ClMenu from 'common/components/menu/menu.vue'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
import GlobalSpinner from 'common/components/global-spinner/global-spinner.vue'
import { consoleError } from 'vuetify/es5/util/console'

export default {
  components: {ClMenu,GlobalSpinner,DeleteModal},
  props: ['plant', 'devices', 'notificationUid', 'notificationsModules', 'allTopologyFinished'],
  data:
      function () {
          return {
                    CONSTANTS:
                    {
                      PREFIX_NOTIFICATION_ID : 'not_',
                      CHECK_ALL_NOTIFICATIONS_BTN_TITLE : this.$gettext('Delete all alerts'),
                      CHECK_ALL_NOTIFICATIONS : this.$gettext('Do you really want to delete the %{s} alerts ?'),
                      CHECK_NOTIFICATION : this.$gettext('Do you really want to delete the alert ?'),
                      CHECK_ALL_NOTIFICATIONS_SUCCESS : this.$gettext('All alerts has been successfully deleted'),
                      CHECK_ALL_NOTIFICATIONS_ERROR : this.$gettext('Unable to delete all alerts'),
                      CHECK_NOTIFICATION_SUCCESS : this.$gettext('The alert has been successfully deleted'),
                      CHECK_NOTIFICATION_ERROR : this.$gettext('Unable to delete the alert'),
                      LIMIT_DISPLAYED_ELEMENTS : 50,
                      LIMIT_TOTAL_DISPLAYED_ELEMENTS : 100,
                      SCROLLTO_CONFIG :
                      {
                        container: '.list-container',
                        duration: 500,
                        easing: 'ease',
                        offset: 0,
                        cancelable: true,
                        onDone: false,
                        onCancel: false,
                        x: false,
                        y: true
                      }
                    },
                    displayCheckAllNotificationsModal:
                    {
                      visible: false,
                      text:''
                    },
                    header:
                    {
                      createdAt :
                      {
                        asc:false,
                        title:'Dates',
                        visible: false
                      },
                      moduleName :
                      {
                        asc:true,
                        title:'Devices',
                        visible: false
                      },
                      event :
                      {
                        asc:true,
                        title:'Alerts',
                        visible: false
                      }
                    },
                    notifications: [],
                    notificationIndexBegin: 0,
                    displayedNotifications: [],
                    loading: true,
                    spinnerButton: false,
          }
  },

  computed: {
   /* rightPanelNotifications: function () {
      console.log('computed');
      var not = _orderBy(this.notifications, ['moduleName', 'createdAt'], ['asc', 'desc']);
      return not;
    }*/
  },
  mounted: function (val, oldVal) {
    if(this.notificationsModules.length>0)
    {
      this.initAlertsList(this.notificationsModules);
    }
    
  },

  watch: {
    notificationsModules: {
      handler: function (val, oldVal) {
        this.initAlertsList(val);
      },
      deep: true
    }
  },

  methods:
  {

    scroll: function(col){
      var listContainer = document.querySelector('.list-container');

      var top = listContainer.scrollTop == 0;
      var bottom = listContainer.offsetHeight + listContainer.scrollTop == listContainer.scrollHeight;
      
      if (top && this.notificationIndexBegin>this.CONSTANTS.LIMIT_TOTAL_DISPLAYED_ELEMENTS) 
      {
        var indexEnd = this.notificationIndexBegin - this.CONSTANTS.LIMIT_TOTAL_DISPLAYED_ELEMENTS;
        var indexBegin = indexEnd - this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS;
        this.notificationIndexBegin-=this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS;
        this.displayedNotifications = this.notifications.slice(indexBegin, indexEnd).concat(this.displayedNotifications);
        listContainer.scrollTop = 1;
        var indexBeginSplice = this.displayedNotifications.length - this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS;
        this.displayedNotifications.splice(indexBeginSplice,this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS);
      }

      else if (bottom && this.notificationIndexBegin<this.notifications.length) 
      {
        var addNotificationsBottom = this.notifications.slice(this.notificationIndexBegin, this.notificationIndexBegin+this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS);
        this.displayedNotifications = this.displayedNotifications.concat(addNotificationsBottom);
        if(this.displayedNotifications.length > this.CONSTANTS.LIMIT_TOTAL_DISPLAYED_ELEMENTS)
        {
          this.displayedNotifications.splice(0,this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS);
          listContainer.scrollTop -= 2450;
        }
        this.notificationIndexBegin += (addNotificationsBottom.length<this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS?addNotificationsBottom.length:this.CONSTANTS.LIMIT_DISPLAYED_ELEMENTS);
      }
    },


    sortColumn: function(col){
      var otherCol = (col=='moduleName'?'createdAt':'moduleName');
      this.header[col].visible = true;
      this.header[otherCol].visible = false;
      this.header[col].asc = !this.header[col].asc;
      this.notifications = _orderBy(this.notifications, col, (this.header[col].asc ? 'asc' : 'desc'));
      this.initDisplayedNotifications();
    },

    init: function(){
      this.loading=  false;
    },

    initAlertsList: function(notifications){
      notifications = _filter(notifications, (n) => { return n.plantId == this.$store.state.selectedPlantId; });
      if(this.notificationUid)
      {
        this.notifications = _orderBy(NotificationResource.getModulesNotifications(notifications), ['moduleName', 'createdAt'], ['asc', 'desc']);
        this.$nextTick(function() {
          this.$scrollTo( '#'+this.CONSTANTS.PREFIX_NOTIFICATION_ID+this.notificationUid,this.CONSTANTS.SCROLLTO_CONFIG);
        });
      }
      else
        this.notifications = _orderBy(NotificationResource.getModulesNotifications(notifications), 'createdAt', 'desc');
      this.initDisplayedNotifications();
    },

    initDisplayedNotifications: function(){
      var listContainer = document.querySelector('.list-container');
      this.displayedNotifications = this.notifications.slice(0, this.CONSTANTS.LIMIT_TOTAL_DISPLAYED_ELEMENTS);
      this.notificationIndexBegin = this.CONSTANTS.LIMIT_TOTAL_DISPLAYED_ELEMENTS;
      if(listContainer)
      {
        listContainer.addEventListener('scroll', this.scroll);
        listContainer.scrollTop = 0;
      }
    },

    checkAllNotifications: function(){
      if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
			{
			  return false;
			}
      this.displayCheckAllNotificationsModal.visible = true;
      this.displayCheckAllNotificationsModal.text = (this.notifications.length>1?this.$gettextInterpolate(this.CONSTANTS.CHECK_ALL_NOTIFICATIONS, {s: this.notifications.length}):this.CONSTANTS.CHECK_NOTIFICATION);
    },

    checkAllNotificationsValidate: function()
    {

      var _this = this;
      NotificationResource.seeAllNotifications().then(
        function(response) {
          _this.displayCheckAllNotificationsModal.visible = false;
          _this.notifications = [];
          _this.removeAllNotificationsBadges();
          _this.eventHub.$emit('displayAlert',  _this.CONSTANTS.CHECK_ALL_NOTIFICATIONS_SUCCESS, 'success');
          _this.eventHub.$emit('setLoading',false);
        }).catch(
        function(e) {
          if(e.response.status == 401)
            _this.eventHub.$emit('logout');
          else
            _this.eventHub.$emit('displayAlert',  _this.CONSTANTS.CHECK_ALL_NOTIFICATIONS_ERROR);
          _this.eventHub.$emit('setLoading',false);
      });
    },
    removeAllNotificationsBadges: function()
    {
      if(this.plant!=undefined)
      {
        if(this.plant.zones!=undefined)
        {
          this.plant.zones.forEach(function(z) {
            if(z.modules!=undefined)
            {
              z.modules.forEach(function(m) {
                if(m.notification!=undefined)
                {
                  m.notification.notifications = [];
                }
              });
            }
          });
        }
      }
      this.notificationsModules.splice(0,this.notificationsModules.length);
    },

    removeNotificationBadge: function(id)
    {
      if(this.plant!=undefined)
      {
        this.plant.zones.forEach(function(z) {
          if(z.modules!=undefined)
          {
            z.modules.forEach(function(m) {
              if(m.notification!=undefined && m.notification.notifications.length > 0)
              {
                var index = _findIndex(m.notification.notifications, function(n) { 
                  return (n.id == id || n.commandId == id); 
                });
                if(index!=-1)
                {
                  m.notification.notifications.splice(index, 1);
                  return;
                }
              }
            });
          }
        });
      }
    },

    swipeNotification (notification) {
      var _this = this;
      NotificationResource.seeNotification(notification.moduleId,(notification.id?notification.id:notification.commandId)).then(
        function(response) {
          var notificationId = (notification.id?notification.id:notification.commandId);
          var index = ArrayService.indexOf(_this.notifications,'id',notificationId);
          var indexDisplayNotification = ArrayService.indexOf(_this.displayedNotifications,'id',notificationId);
          _this.notifications.splice(index, 1);
          _this.displayedNotifications.splice(indexDisplayNotification, 1);
          _this.removeNotificationBadge(notificationId);
          _this.eventHub.$emit('displayAlert',  _this.CONSTANTS.CHECK_NOTIFICATION_SUCCESS, 'success');
        }).catch(
        function(e) {
          if(e.response.status == 401)
            _this.eventHub.$emit('logout');
          else
            _this.eventHub.$emit('displayAlert',  _this.CONSTANTS.CHECK_NOTIFICATION_ERROR);
      });
    },

    moduleRoom: function(id){
      var obj =_find(this.devices, function(o) { return o.id == id; });
      return (obj?this.$gettext(obj.zoneName):'');
    },

    firstNotif: function(index){
      if(index == 0)
        return 'borderTop: solid 1px #7D7D7D;'
      else
        return ''
    }
  }
}
